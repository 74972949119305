import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormTuristic from './FormTuristic';
import TransportCard from './TransportCards';
import FormPayment from './FormPayment';
import { useLocation } from "react-router-dom";



const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

// Función principal
export default function BookingForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStep = (step) => () => setActiveStep(step);

  const handleComplete = () => {
    setCompleted({ ...completed, [activeStep]: true });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // Renderiza el formulario adecuado basado en el paso activo
  const renderForm = () => {
    switch (activeStep) {
      case 0:
        return <FormTuristic handleNext={handleNext} />;
      case 1:
        return <TransportCard handleNext={handleNext} />;
      case 2:
        return <FormPayment handleNext={handleNext}  />;
      default:
        return null;
    }
  };

  return (
<div>

    <Box sx={{ width: '95%', margin: 'auto' }}>
      <Stepper nonLinear activeStep={activeStep} >
        {steps.map((label, index) => (
          <Step  key={label} completed={completed[index]} >
            <StepButton color="inherit" onClick={handleStep(index)}/>
            {label}
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you're finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
        
            {renderForm()}
            
          
          </React.Fragment>
        )}
      </div>
    </Box>

  
</div>
  );
}
