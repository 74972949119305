import BookingFormComponent from '../components/BookingForm/BookingForm';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

export default function BookingForm() {
    return (
        <div>
            <Navbar/>
           <BookingFormComponent/>
           <Footer/>
        </div>
    )
}