import Footer from "../components/Footer/Footer";
import HomeComponent from "../components/Home/Home";

export default function Home() {
    return (
        <div>
                <HomeComponent/>
                <Footer/>

        </div>
    )
}