import ContactComponent from '../components/Contact/Contact';
import Footer from "../components/Footer/Footer";

export default function Contact() {
    return (
        <div>
            <ContactComponent/>
            <Footer/>

        </div>
    )
}