import RatesComponent from '../components/Rates/Rates';

import Foote from '../components/Footer/Footer';


export default function Rates() {
    return (
        <div>
            <RatesComponent/>
            <Foote/>
        </div>
    )
}