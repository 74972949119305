import React, { useState, useEffect } from "react";
import axios from 'axios';

const FormPayment = () => {
  const [data, setData] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    comment: "",
    currency: "",
    departureDate: "",
    departureTime: "",
    country: "",
    from: "",
    isReturn: "",
    passengers: "",
    returnDate: "",
    returnTime: "",
    to: "",
    vehicles: "",
    price: "",
    
  });
  const [storageForm, setStorageForm] = useState(null);


useEffect(() => {
  const res = JSON.parse(localStorage.getItem("formData")) || {}; // Parseamos el JSON o devolvemos un objeto vacío si no existe
  setStorageForm(res); // Actualizamos el estado con los datos obtenidos
}, [data]); // 'data' podría no ser necesario en la lista de dependencias

      useEffect(() => {
        setData({
          ...data,

          currency: storageForm &&  storageForm.currency,
          departureDate: storageForm &&  storageForm.departureDate,
          departureTime:storageForm &&  storageForm.departureTime,
          from: storageForm &&  storageForm.from,
          isReturn: storageForm &&  storageForm.isReturn,
          passengers: storageForm &&  storageForm.passengers,
          returnDate: storageForm &&  storageForm.returnDate,
          returnTime: storageForm &&  storageForm.returnTime,
          to: storageForm &&  storageForm.to,
          vehicles: storageForm &&  storageForm.transportation.vehicles,
          price: storageForm &&  storageForm.transportation.price,
          
        })
   
       }, [storageForm]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handlePaymentChange = (method) => {
    setData({
      ...data,
      paymentMethod: method,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("http://localhost:3001/api/reservate", data)
      
    } catch (error) {
      
    }
  };

  return (
    <div className="FormPayment-container">
      <div className="title-client">
        <strong>Client information</strong>
        <hr />
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flight-form">
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={data.name}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={data.email}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={data.phone}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              id="country"
              name="country"
              value={data.country}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="airline">Airline:</label>
            <input
              type="text"
              id="airline"
              name="airline"
              value={data.airline}
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="flightNumber">Flight Number:</label>
            <input
              type="text"
              id="flightNumber"
              name="flightNumber"
              value={data.flightNumber}
              onChange={handleChange}
            />
          </div>

          <div className="full-width">
            <label htmlFor="comments">Comments:</label>
            <textarea
              id="comment"
              name="comment"
              value={data.comment}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>

        <div className="title-client">
          <strong>Payment methods</strong>
          <hr />
        </div>

        <div className="payment-methods">
          <div className="payment-option">
            <img
              src="https://www.conectasoftware.com/wp-content/uploads/2020/03/Paypal_logo.png"
              alt="PayPal"
              className={data.paymentMethod === "PayPal" ? "selected" : ""}
            />
            <button
              type="button"
              className={data.paymentMethod === "PayPal" ? "selected" : ""}
              onClick={() => handlePaymentChange("PayPal")}
            >
              Seleccionar PayPal
            </button>
          </div>

          <div className="payment-option">
            <img
              src="https://png.pngtree.com/png-clipart/20200224/original/pngtree-cash-in-hand-icon-cartoon-style-png-image_5211912.jpg"
              alt="Efectivo"
              className={data.paymentMethod === "Efectivo" ? "selected" : ""}
            />
            <button
              type="button"
              className={data.paymentMethod === "Efectivo" ? "selected" : ""}
              onClick={() => handlePaymentChange("Efectivo")}
            >
              Seleccionar Efectivo
            </button>
          </div>
        </div>
        <div className="full-width">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default FormPayment;
