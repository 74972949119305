import React, {useEffect, useState} from 'react'
import AOS from 'aos';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link, useLocation} from 'react-router-dom'
import GLightbox from 'glightbox'; // Importa GLightbox
import { BsList } from "react-icons/bs";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IoMdArrowUp } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { BsWhatsapp } from "react-icons/bs";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";



export default function Contact() {
  const { pathname } = useLocation();
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [messageSuccess, setMesageSuccess] = useState(false);

  const [t, i18n] = useTranslation("global");
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language); // Guardamos el idioma seleccionado en localStorage
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language"); // Intentamos obtener el idioma de localStorage
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Si existe, lo usamos
    } else {
      // Si no existe, podemos usar un idioma predeterminado (por ejemplo, 'en')
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  useEffect(() => {
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (!selectHeader) return;
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };

    // Añadir eventos de scroll
    document.addEventListener("scroll", toggleScrolled);
    toggleScrolled(); // Llamar inmediatamente para verificar si la página ya está scrolleada.

    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    const mobileNavToogle = () => {
      document.querySelector("div").classList.toggle("mobile-nav-active"); // Cambiar 'div' por un selector más específico
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener("click", mobileNavToogle);
    }

    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", function (e) {
          e.preventDefault();
          this.parentNode.classList.toggle("active");
          this.parentNode.nextElementSibling.classList.toggle(
            "dropdown-active"
          );
          e.stopImmediatePropagation();
        });
      });

    // Eliminar el preloader inmediatamente si existe
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      preloader.remove();
    }

    let scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const toggleScrollTop = () => {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };

      scrollTop.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

      document.addEventListener("scroll", toggleScrollTop);
      toggleScrollTop(); // Verificar estado inicial del scroll-top
    }

    // Inicialización de AOS (Animaciones)
    const aosInit = () => {
      if (typeof AOS !== "undefined") {
        AOS.init({
          duration: 600,
          easing: "ease-in-out",
          once: true,
          mirror: false,
        });
        AOS.refresh();
      }
    };
    aosInit(); // Llamar directamente para inicializar sin depender de 'load'

    // Inicialización de Swiper
    const initSwiper = () => {
      document
        .querySelectorAll(".init-swiper")
        .forEach(function (swiperElement) {
          const configElement = swiperElement.querySelector(".swiper-config");
          if (configElement) {
            let config = JSON.parse(configElement.innerHTML.trim());
            if (!swiperElement.classList.contains("swiper-tab")) {
              new Swiper(swiperElement, config);
            }
          }
        });
    };
    initSwiper(); // Llamar directamente sin depender de 'load'

    // Limpieza al desmontar el componente
    return () => {
      document.removeEventListener("scroll", toggleScrolled);
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener("click", mobileNavToogle);
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSuccess(true)
    setTimeout( async () => {
      
    try {
      //await dispatch(ContactUs(data));
      console.log("enviado")
    } catch (error) {
      console.error(error);
    } finally{
    setLoadingSuccess(false)
    setMesageSuccess(true)


    }
  }, 1000);

  };
  const handleChange = (e) => {
    e.preventDefault();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
    return (
        <div class="contact-page">

<header id="header" class="header d-flex align-items-center fixed-top">
          <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
      
            <Link href="/" class="logo d-flex align-items-center">
             <img src={require("../../assets/img/Home/logo.jpg")} alt="" />
            </Link>
      
            <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <Link to="/" >
                  {t("navbar.home")}
                </Link>
              </li>
              <li>
                <Link to="/about">{t("navbar.about")}</Link>
              </li>
              {/*  <li><Link to="/services">Services</Link></li>*/}
              <li>
                <Link to="/rates">{t("navbar.rates")}</Link>
              </li>
              <li>
                <Link to="/contact" class="active">{t("navbar.contact")}</Link>
              </li>

              <Link to={"/book-now"}>
                <Button variant="contained">{t("navbar.book")}</Button>
              </Link>

              <select
                onChange={(e) => handleLanguageChange(e.target.value)}
                value={i18n.language}
                className="lenguage"
              >
                <option value="en">EN</option>
                <option value="es">ES</option>
              </select>
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list">
              <BsList />
            </i>
          </nav>
      
          </div>
        </header>
      
        <main class="main">
        <div class="page-title dark-background">
          <div
            class="container position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h1>{t("navbar.contact")}</h1>
          </div>
        </div>

        <section id="contact" class="contact section">
          <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div class="row gy-4">
              <div class="col-lg-6">
                <div
                  class="info-item d-flex flex-column justify-content-center align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="bi bi-geo-alt">
                    <BsWhatsapp />
                  </i>
                  <h3>WhatsApp</h3>
                  <a href="https://wa.me/+525532230784" target="__blank">
                    {" "}
                    +52 (55) 3223 0784
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div
                  class="info-item d-flex flex-column justify-content-center align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="bi bi-telephone">
                    <LocalPhoneIcon />
                  </i>
                  <h3>{t("footer.phone")}</h3>
                  <a href="tel:+525532230784">+52 (55) 3223 0784</a>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <div
                  class="info-item d-flex flex-column justify-content-center align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="bi bi-envelope">
                    <EmailIcon />
                  </i>
                  <h3>{t("footer.email")}</h3>
                  <p>
                    <a href={`mailto:rrgonzaga@comunidad.unam.mx`}>
                      rrgonzaga@comunidad.unam.mx
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div class="row gy-4 mt-1">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus" frameborder="0" style={{border:0, width: "100%"  ,height: "400px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

              <div class="col-lg-6">
                <form
                  onSubmit={handleSubmit}
                  class="php-email-form"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div class="row gy-4">
                    <div class="col-md-6">
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        class="form-control"
                        placeholder={t("contact.name")}
                        required
                      />
                    </div>

                    <div class="col-md-6 ">
                      <input
                        type="email"
                        class="form-control"
                        onChange={handleChange}
                        name="email"
                        placeholder={t("contact.email")}
                        required=""
                      />
                    </div>

                    <div class="col-md-12">
                      <input
                        type="text"
                        class="form-control"
                        onChange={handleChange}
                        name="subject"
                        placeholder={t("contact.affair")}
                        required
                      />
                    </div>

                    <div class="col-md-12">
                      <textarea
                        class="form-control"
                        name="message"
                        onChange={handleChange}
                        rows="6"
                        placeholder={t("contact.message")}
                        required
                      ></textarea>
                    </div>

                    <div class="col-md-12 text-center">
                      <div class="loading">Loading</div>
                      <div class="error-message"></div>
                      <div class="sent-message">
                        Your message has been sent. Thank you!
                      </div>

                      <button type="submit">   {loadingSuccess ? (
          <CircularProgress size={25} thickness={5} sx={{ color: "#fff" }} />
        ) : (
          "Enviar mensaje"
        )}</button>

        {messageSuccess ? (

          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
    Mensaje enviado corectamente gracias por comunicarse con nosotros.
    </Alert>
        ): null}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      
       
        <a
        href="#"
        id="scroll-top"
        class="scroll-top d-flex align-items-center justify-content-center"
      >
        <IoMdArrowUp class="icon-arrow-up" />

      </a>
      </div>
    )
}