import React, { useEffect } from "react";
import AOS from "aos";
import imagesLoaded from "imagesloaded";
import Isotope from "isotope-layout";
import { Swiper, SwiperSlide } from "swiper/react";
import "aos/dist/aos.css"; // Importa los estilos de AOS
import { Link, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';

import { BsList } from "react-icons/bs";

import GLightbox from "glightbox"; // Importa GLightbox
import { useTranslation } from "react-i18next";
import "glightbox/dist/css/glightbox.css"; // Asegúrate de importar el CSS

export default function Rates() {
  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language'); // Intentamos obtener el idioma de localStorage
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Si existe, lo usamos
    } else {
      // Si no existe, podemos usar un idioma predeterminado (por ejemplo, 'en')
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language); // Guardamos el idioma seleccionado en localStorage
  };
  useEffect(() => {
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (!selectHeader) return;
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };
    const glightbox = GLightbox({
      selector: ".glightbox",
    });
    // Añadir eventos de scroll
    document.addEventListener("scroll", toggleScrolled);
    toggleScrolled(); // Llamar inmediatamente para verificar si la página ya está scrolleada.

    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    const mobileNavToogle = () => {
      document.querySelector("div").classList.toggle("mobile-nav-active"); // Cambiar 'div' por un selector más específico
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener("click", mobileNavToogle);
    }

    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", function (e) {
          e.preventDefault();
          this.parentNode.classList.toggle("active");
          this.parentNode.nextElementSibling.classList.toggle(
            "dropdown-active"
          );
          e.stopImmediatePropagation();
        });
      });

    // Eliminar el preloader inmediatamente si existe
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      preloader.remove();
    }

    let scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const toggleScrollTop = () => {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };

      scrollTop.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

      document.addEventListener("scroll", toggleScrollTop);
      toggleScrollTop(); // Verificar estado inicial del scroll-top
    }

    // Inicialización de AOS (Animaciones)
    const aosInit = () => {
      if (typeof AOS !== "undefined") {
        AOS.init({
          duration: 600,
          easing: "ease-in-out",
          once: true,
          mirror: false,
        });
        AOS.refresh();
      }
    };
    aosInit(); // Llamar directamente para inicializar sin depender de 'load'

    // Inicialización de Swiper
    const initSwiper = () => {
      document
        .querySelectorAll(".init-swiper")
        .forEach(function (swiperElement) {
          const configElement = swiperElement.querySelector(".swiper-config");
          if (configElement) {
            let config = JSON.parse(configElement.innerHTML.trim());
            if (!swiperElement.classList.contains("swiper-tab")) {
              new Swiper(swiperElement, config);
            }
          }
        });
    };
    initSwiper(); // Llamar directamente sin depender de 'load'

    // Limpieza al desmontar el componente
    return () => {
      document.removeEventListener("scroll", toggleScrolled);
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener("click", mobileNavToogle);
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <header id="header" class="header d-flex align-items-center fixed-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <Link href="/" class="logo d-flex align-items-center">
            <img src={require("../../assets/img/Home/logo.jpg")} alt="" />
          </Link>

          <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <Link to="/" >
                {t("navbar.home")}
                </Link>
              </li>
              <li>
                <Link to="/about" >          
                      {t("navbar.about")}
                </Link>
              </li>
              {/*  <li><Link to="/services">Services</Link></li>*/}
              <li>
                <Link to="/rates" class="active">
                {t("navbar.rates")}
                
                </Link>
              </li>
              <li>
                <Link to="/contact">
                {t("navbar.contact")}
                
                </Link>
              </li>

              <Link to={"/book-now"}>
              <Button variant="contained">

              {t("navbar.book")}

              </Button>
                  </Link>

                  <select
        onChange={(e) => handleLanguageChange(e.target.value)}
        value={i18n.language}
      >
        <option value="en">EN</option>
        <option value="es">ES</option>
      </select>
    
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list">
              <BsList />
            </i>
        
          </nav>
        </div>
      </header>
      <div class="page-title dark-background">
        <div
          class="container position-relative"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <h1>{t("navbar.rates")}</h1>
        </div>
      </div>
      <div id="rates-table">
        <p>
        {t("table.title")}
        </p>
        <p>
          <strong>{t("table.subTitle")}</strong>
        </p>
        <table>
          <thead>
            <tr>
              <th rowspan="2">AREAS</th>
              <th colspan="2">
                SUBURBAN
                <br />
                <small>Up to 5 passengers</small>
              </th>
              <th colspan="2">
                ESCALADE
                <br />
                <small>Up to 5 passengers</small>
              </th>
              <th colspan="2">
                HIACE/VAN
                <br />
                <small>Up to 10 passengers</small>
              </th>
              <th colspan="2">
                MINI BUS
                <br />
                <small>Up to 17 passengers</small>
              </th>
            </tr>
            <tr>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.round_trip")}</th>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.round_trip")}</th>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.round_trip")}</th>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.round_trip")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>San Jose del Cabo</th>
              <td>$70</td>
              <td>$140</td>
              <td>$95</td>
              <td>$190</td>
              <td>$75</td>
              <td>$150</td>
              <td>$135</td>
              <td>$270</td>
            </tr>
            <tr>
              <th>Corridor</th>
              <td>$85</td>
              <td>$170</td>
              <td>$100</td>
              <td>$200</td>
              <td>$90</td>
              <td>$180</td>
              <td>$145</td>
              <td>$290</td>
            </tr>
            <tr>
              <th>Puerto Los Cabos</th>
              <td>$85</td>
              <td>$170</td>
              <td>$95</td>
              <td>$190</td>
              <td>$90</td>
              <td>$180</td>
              <td>$155</td>
              <td>$310</td>
            </tr>
            <tr>
              <th>Encanto la Laguna</th>
              <td>$100</td>
              <td>$200</td>
              <td>$115</td>
              <td>$230</td>
              <td>$100</td>
              <td>$200</td>
              <td>$160</td>
              <td>$320</td>
            </tr>
            <tr>
              <th>Cabo San Lucas</th>
              <td>$100</td>
              <td>$210</td>
              <td>$120</td>
              <td>$240</td>
              <td>$105</td>
              <td>$210</td>
              <td>$165</td>
              <td>$330</td>
            </tr>
            <tr>
              <th>Pacific</th>
              <td>$110</td>
              <td>$220</td>
              <td>$130</td>
              <td>$260</td>
              <td>$110</td>
              <td>$220</td>
              <td>$170</td>
              <td>$340</td>
            </tr>
            <tr>
              <th>Todos Santos</th>
              <td>$250</td>
              <td>$500</td>
              <td>$240</td>
              <td>$480</td>
              <td>$260</td>
              <td>$520</td>
              <td>$300</td>
              <td>$600</td>
            </tr>
            <tr>
              <th>Los Barriles</th>
              <td>$220</td>
              <td>$440</td>
              <td>$240</td>
              <td>$480</td>
              <td>$230</td>
              <td>$460</td>
              <td>$300</td>
              <td>$600</td>
            </tr>
            <tr>
              <th>La Paz</th>
              <td>$290</td>
              <td>$580</td>
              <td>$360</td>
              <td>$720</td>
              <td>$300</td>
              <td>$600</td>
              <td>$450</td>
              <td>$900</td>
            </tr>
            <tr>
              <th>Diamante</th>
              <td>$125</td>
              <td>$250</td>
              <td>$135</td>
              <td>$270</td>
              <td>$125</td>
              <td>$250</td>
              <td>$185</td>
              <td>$370</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="hotel-rates-table">
        <h2>TRANSFERS BETWEEN HOTELS RATES</h2>
        <table>
          <thead>
            <tr>
              <th rowspan="2">AREAS</th>
              <th >
                SUBURBAN
                <br />
                <small>Up to 5 passengers</small>
              </th>
              <th>
                ESCALADE
                <br />
                <small>Up to 5 passengers</small>
              </th>
              <th>
                HIACE/VAN
                <br />
                <small>Up to 10 passengers</small>
              </th>
              <th>
                MINI BUS
                <br />
                <small>Up to 17 passengers</small>
              </th>
            </tr>
            <tr>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.one_wey")}</th>
              <th>{t("table.one_wey")}</th>
             
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>San Jose del Cabo - Tourist Corridor</td>
              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$130</td>
             
            </tr>
            <tr>
              <td>San Jose del Cabo - Cabo San Lucas</td>
              <td>$99</td>
              <td>$105</td>
              <td>$100</td>
              <td>$145</td>
            
            </tr>
            <tr>
              <td>San Jose del Cabo - Pacific</td>
              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <th>San Jose del Cabo - Puerto Los Cabos</th>
              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$125</td>
            </tr>
            <tr>
              <th>San Jose del Cabo - Los Barriles</th>

              <td>$240</td>
              <td>$255</td>
              <td>$250</td>
              <td>$340</td>
            </tr>
            <tr>
              <th>San Jose del Cabo - Encanto la Laguna</th>

              <td>$85</td>
              <td>$100</td>
              <td>$95</td>
              <td>$150</td>
            </tr>
            <tr>
              <th>San Jose del Cabo - Diamante</th>

              <td>$110</td>
              <td>$125</td>
              <td>$120</td>
              <td>$170</td>
            </tr>
            <tr>
              <th>Turist Corridor - San Jose del Cabo</th>
              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$140</td>
            </tr>
            <tr>
              <th>Turist Corridor - Pacific</th>

              <td>$90</td>
              <td>$105</td>
              <td>$100</td>
              <td>$145</td>
            </tr>
            <tr>
              <th>Turist Corridor - Puertos Los Cabos</th>

              <td>$85</td>
              <td>$100</td>
              <td>$95</td>
              <td>$140</td>
            </tr>
            <tr>
              <th>Turist Corridor - Los Barriles</th>

              <td>$230</td>
              <td>$245</td>
              <td>$240</td>
              <td>$365</td>
            </tr>
            <tr>
              <th>Turist Corridor - Encanto la laguna</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$145</td>
            </tr>
            <tr>
              <th>Turist Corridor - Diamante</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - San Jose del Cabo</th>

              <td>$90</td>
              <td>$105</td>
              <td>$100</td>
              <td>$145</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - Turist Corridor</th>

              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$140</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - Pacific</th>

              <td>$85</td>
              <td>$100</td>
              <td>$95</td>
              <td>$145</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - Puerto Los Cabos</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$155</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - La Paz</th>

              <td>$325</td>
              <td>$340</td>
              <td>$335</td>
              <td>$450</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - Los Barriles</th>

              <td>$240</td>
              <td>$255</td>
              <td>$250</td>
              <td>$380</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - Encanto la Laguna</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$165</td>
            </tr>
            <tr>
              <th>Cabo San Lucas - Diamante</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <th>Pacific - San Jose Cabo</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <th>Pacific - Turist Corridor</th>

              <td>$90</td>
              <td>$105</td>
              <td>$100</td>
              <td>$145</td>
            </tr>
            <tr>
              <th>Pacific - Cabo San Lucas</th>

              <td>$85</td>
              <td>$100</td>
              <td>$95</td>
              <td>$145</td>
            </tr>
            <tr>
              <th>Pacific - Puerto Los Cabos</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$160</td>
            </tr>
            <tr>
              <th>Pacific - Los Barriles</th>

              <td>$260</td>
              <td>$275</td>
              <td>$270</td>
              <td>$390</td>
            </tr>
            <tr>
              <th>Pacific - Encanto la laguna</th>

              <td>$110</td>
              <td>$125</td>
              <td>$120</td>
              <td>$170</td>
            </tr>
            <tr>
              <th>Pacific - Diamante</th>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Puerto Los Cabos - San Jose del Cabo</td>

              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$125</td>
            </tr>
            <tr>
              <td>Puerto Los Cabos - Turist Corridor</td>

              <td>$85</td>
              <td>$100</td>
              <td>$95</td>
              <td>$140</td>
            </tr>
            <tr>
              <td>Puerto Los Cabos - Cabo San Lucas</td>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$155</td>
            </tr>
            <tr>
              <td>Puerto Los Cabos - Pacific</td>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$160</td>
            </tr>
            <tr>
              <td>Puerto Los Cabos - Encanto la Laguna</td>

              <td>$90</td>
              <td>$105</td>
              <td>$100</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Puerto Los Cabos - Diamante</td>

              <td>$120</td>
              <td>$135</td>
              <td>$130</td>
              <td>$180</td>
            </tr>
            <tr>
              <td>Los Barriles - San Jose del Cabo</td>

              <td>$240</td>
              <td>$255</td>
              <td>$250</td>
              <td>$340</td>
            </tr>
            <tr>
              <td>Los Barriles - Turist Corridor</td>

              <td>$250</td>
              <td>$265</td>
              <td>$260</td>
              <td>$365</td>
            </tr>
            <tr>
              <td>Los Barriles - Cabo San Lucas</td>

              <td>$270</td>
              <td>$285</td>
              <td>$280</td>
              <td>$380</td>
            </tr>
            <tr>
              <td>Los Barriles - Pacific</td>

              <td>$280</td>
              <td>$295</td>
              <td>$290</td>
              <td>$390</td>
            </tr>
            <tr>
              <td>Los Barriles - La Paz</td>

              <td>$365</td>
              <td>$380</td>
              <td>$375</td>
              <td>$455</td>
            </tr>
            <tr>
              <td>Encanto la Laguna - San Jose del Cabo</td>

              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Encanto la Laguna - Turist Corridor</td>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$145</td>
            </tr>
            <tr>
              <td>Encanto la Laguna - Cabo San Lucas</td>

              <td>$110</td>
              <td>$125</td>
              <td>$120</td>
              <td>$165</td>
            </tr>
            <tr>
              <td>Encanto la Laguna - Pacific</td>

              <td>$110</td>
              <td>$125</td>
              <td>$120</td>
              <td>$170</td>
            </tr>
            <tr>
              <td>Encanto la Laguna - Puerto Los Cabos</td>

              <td>$80</td>
              <td>$95</td>
              <td>$90</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Encanto la Laguna - Diamante</td>

              <td>$120</td>
              <td>$135</td>
              <td>$130</td>
              <td>$190</td>
            </tr>
            <tr>
              <td>Diamante - San Jose del Cabo</td>

              <td>$110</td>
              <td>$125</td>
              <td>$120</td>
              <td>$170</td>
            </tr>
            <tr>
              <td>Diamante - Turist Corridor</td>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Diamante - Cabo San Lucas</td>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Diamante - Pacific</td>

              <td>$100</td>
              <td>$115</td>
              <td>$110</td>
              <td>$150</td>
            </tr>
            <tr>
              <td>Diamante - Puerto Los Cabos</td>

              <td>$120</td>
              <td>$135</td>
              <td>$130</td>
              <td>$180</td>
            </tr>
            <tr>
              <td>Diamante - Encanto la Laguna</td>

              <td>$120</td>
              <td>$135</td>
              <td>$130</td>
              <td>$190</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
