import './App.css';
import {BrowserRouter as Router, Routes, Route, } from 'react-router-dom'
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Test from './pages/Test';
import BookingForm from './pages/BookingForm';
import Rates from './pages/Rates';
import PanelAdmin from './pages/PanelAdmin';
import TableReservated from './components/PanelAdmin/TableReservated';





function App() {
  return (
    <div >
      <Router>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/book-now" element={<BookingForm/>} />

        <Route path="/rates" element={<Rates/>} />

        <Route path="/panel" element={<PanelAdmin/>} >
        <Route index element={<TableReservated/>} />

        
        <Route path="reservations" element={<TableReservated/>} />
        
        </Route>

       

        <Route path="/test" element={<Test/>} />

        </Routes>
      </Router>
    
    </div>
  );
}

export default App;
