import React, { useState } from "react";
import axios from "axios";

const PaymentForm = () => {
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [paymentStatus, setPaymentStatus] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verifica si el monto es un número válido
    if (isNaN(amount) || amount <= 0) {
      alert("Por favor ingrese un monto válido");
      return;
    }

    const paymentData = {
      amount: amount,
      currency: currency,
    };

    try {
      // Envia la solicitud al servidor para crear el pago
      const response = await axios.post("http://localhost:3001/api/paypal/payment", paymentData);

      if (response.data.status === "success") {
        setPaymentStatus("Pago exitoso. Redirigiendo...");
        // Aquí puedes redirigir al usuario a la URL de éxito de PayPal o tu propia página
        window.location.href = response.data.approvalUrl;
      } else {
        setPaymentStatus("Error en el procesamiento del pago");
      }
    } catch (error) {
      console.error("Error al procesar el pago:", error);
      setPaymentStatus("Error en el procesamiento del pago");
    }
  };

  return (
    <div>
      <h2>Formulario de Pago</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Monto:</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Moneda:</label>
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="MXN">MXN</option>
            {/* Agrega más monedas si es necesario */}
          </select>
        </div>
        <button type="submit">Pagar con PayPal</button>
      </form>

      {paymentStatus && <p>{paymentStatus}</p>}
    </div>
  );
};

export default PaymentForm;
