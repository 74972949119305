import AboutComponent from '../components/AboutComponent/About';
import Footer from "../components/Footer/Footer";

export default function About() {
    return(
        <div>
            <AboutComponent/>
            <Footer/>

        </div>
    )
}