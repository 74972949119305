import React, { useEffect } from "react";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {

  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language"); // Intentamos obtener el idioma de localStorage
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Si existe, lo usamos
    } else {
      // Si no existe, podemos usar un idioma predeterminado (por ejemplo, 'en')
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  return (
    <footer id="footer" class="footer dark-background">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-6 footer-about">
          <Link href="/" class="logo d-flex align-items-center">
            <img src={require("../../assets/img/Home/logo.jpg")} alt="" />
          </Link>
            <div class="footer-contact pt-3">
              <p>Contacto Dra. Rosalina Romero</p>
              <p class="mt-3">
                <strong>{t("footer.phone")}:</strong>{" "}
                <span>
                  {" "}
                  <a href="tel:+525532230784">+52 (55) 3223 0784</a>
                </span>
              </p>
              <p>
                <strong>Correo electrónico:</strong>{" "}
                <span>
                  <a href={`mailto:rrgonzaga@comunidad.unam.mx`}>
                    rrgonzaga@comunidad.unam.mx
                  </a>
                </span>
              </p>
            </div>
            <div class="social-links d-flex mt-4">
             
              <a href="">
                <i class="bi bi-facebook">
                  <FacebookIcon />
                </i>
              </a>
              <a href="">
                <i class="bi bi-instagram">
                  <InstagramIcon />
                </i>
              </a>
              <a href="">
                <i class="bi bi-facebook">
                  <XIcon />
                </i>
              </a>
              <a href="">
                <i class="bi bi-instagram">
                  <LinkedInIcon />
                </i>
              </a>
            
            </div>
          </div>
          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Menú</h4>
            <ul>
              <li>
                <Link to="/"> {t("footer.home")}</Link>
              </li>
              <li>
                <Link to="/about">{t("footer.about")}</Link>
              </li>
              <li>
                <Link to="/rates">{t("footer.rates")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("footer.contact")}</Link>
              </li>
            </ul>
          </div>
        


          <div class="col-lg-2 col-md-3 footer-links">
            <h4>{t("footer.terms")}</h4>
            <ul>
              <li>
                <Link to="/politica-privacidad">{t("footer.politic")}</Link>
              </li>

              <li>
                <Link to="/politica-privacidad">{t("footer.condition")}</Link>
              </li>
           
            </ul>
          </div>
   
        </div>
      </div>

      <div class="container copyright text-center mt-4">
          <p>
            © <span>Copyright</span>{" "}
            <strong class="px-1 sitename">Baja Blue Transportation</strong>{" "}
            <span>All Rights Reserved</span>
          </p>
       
        </div>
    </footer>
  );
}
