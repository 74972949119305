import React, {useEffect} from 'react'
import AOS from 'aos';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link, useLocation} from 'react-router-dom'
import GLightbox from 'glightbox'; // Importa GLightbox
import { BsList } from "react-icons/bs";
import { IoMdArrowUp } from "react-icons/io";
import 'glightbox/dist/css/glightbox.css'; // Asegúrate de importar el CSS
import Button from '@mui/material/Button';

import { useTranslation } from "react-i18next";


export default function About() {

  const { pathname } = useLocation();
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language'); // Intentamos obtener el idioma de localStorage
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Si existe, lo usamos
    } else {
      // Si no existe, podemos usar un idioma predeterminado (por ejemplo, 'en')
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  // Función para cambiar el idioma y guardarlo en localStorage
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language); // Guardamos el idioma seleccionado en localStorage
  };
  useEffect(() => {
    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (!selectHeader) return;
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };

    // Añadir eventos de scroll
    document.addEventListener("scroll", toggleScrolled);
    toggleScrolled(); // Llamar inmediatamente para verificar si la página ya está scrolleada.

    const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");

    const mobileNavToogle = () => {
      document.querySelector("div").classList.toggle("mobile-nav-active"); // Cambiar 'div' por un selector más específico
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    };

    if (mobileNavToggleBtn) {
      mobileNavToggleBtn.addEventListener("click", mobileNavToogle);
    }

    document.querySelectorAll("#navmenu a").forEach((navmenu) => {
      navmenu.addEventListener("click", () => {
        if (document.querySelector(".mobile-nav-active")) {
          mobileNavToogle();
        }
      });
    });

    document
      .querySelectorAll(".navmenu .toggle-dropdown")
      .forEach((navmenu) => {
        navmenu.addEventListener("click", function (e) {
          e.preventDefault();
          this.parentNode.classList.toggle("active");
          this.parentNode.nextElementSibling.classList.toggle(
            "dropdown-active"
          );
          e.stopImmediatePropagation();
        });
      });

    // Eliminar el preloader inmediatamente si existe
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      preloader.remove();
    }

    let scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const toggleScrollTop = () => {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };

      scrollTop.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

      document.addEventListener("scroll", toggleScrollTop);
      toggleScrollTop(); // Verificar estado inicial del scroll-top
    }

    // Inicialización de AOS (Animaciones)
    const aosInit = () => {
      if (typeof AOS !== "undefined") {
        AOS.init({
          duration: 600,
          easing: "ease-in-out",
          once: true,
          mirror: false,
        });
        AOS.refresh();
      }
    };
    aosInit(); // Llamar directamente para inicializar sin depender de 'load'

    // Inicialización de Swiper
    const initSwiper = () => {
      document
        .querySelectorAll(".init-swiper")
        .forEach(function (swiperElement) {
          const configElement = swiperElement.querySelector(".swiper-config");
          if (configElement) {
            let config = JSON.parse(configElement.innerHTML.trim());
            if (!swiperElement.classList.contains("swiper-tab")) {
              new Swiper(swiperElement, config);
            }
          }
        });
    };
    initSwiper(); // Llamar directamente sin depender de 'load'

    // Limpieza al desmontar el componente
    return () => {
      document.removeEventListener("scroll", toggleScrolled);
      if (mobileNavToggleBtn) {
        mobileNavToggleBtn.removeEventListener("click", mobileNavToogle);
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    return (
        <div class="about-page">

<header id="header" class="header d-flex align-items-center fixed-top">
          <div class="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
      
            <Link href="/" class="logo d-flex align-items-center">
             <img src={require("../../assets/img/Home/logo.jpg")} alt="" />
            </Link>
      
            <nav id="navmenu" class="navmenu">
            <ul>
              <li>
                <Link to="/" >
                {t("navbar.home")}
                </Link>
              </li>
              <li>
                <Link to="/about" class="active">          
                      {t("navbar.about")}
                </Link>
              </li>
              {/*  <li><Link to="/services">Services</Link></li>*/}
              <li>
                <Link to="/rates">
                {t("navbar.rates")}
                
                </Link>
              </li>
              <li>
                <Link to="/contact">
                {t("navbar.contact")}
                
                </Link>
              </li>

              <Link to={"/book-now"}>
              <Button variant="contained">

              {t("navbar.book")}

              </Button>
                  </Link>

                  <select
        onChange={(e) => handleLanguageChange(e.target.value)}
        value={i18n.language}
      >
        <option value="en">EN</option>
        <option value="es">ES</option>
      </select>
    
            </ul>
            <i class="mobile-nav-toggle d-xl-none bi bi-list">
              <BsList />
            </i>
        
          </nav>
      
          </div>
        </header>
      
        <main class="main">
      
          <div class="page-title dark-background" >
            <div class="container position-relative">
              <h1> {t("navbar.about")}</h1>
              <nav class="breadcrumbs">
                <ol>
                  <li><Link to="/">{t("navbar.home")}</Link></li>
                  <li class="current"> {t("navbar.about")}</li>
                </ol>
              </nav>
            </div>
          </div>
          <section id="alt-services-2" class="alt-services-2 section">
  <div class="container">
    <div class="row justify-content-around gy-4">
      <div class="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
        <h3>{t("about.title")}</h3>
        <p>
        {t("about.subTitle")}
        </p>
        <p>
        {t("about.description")}
        </p>

     

      </div>

      <div class="features-image col-lg-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
        <img src={require("../../assets/img/Camionetas/auto-1.jpg")} alt=""/>
      </div>

    </div>
  </div>
</section>

<section id="about" class="about section">
  <div class="container">
    <div class="row position-relative">
      <div class="col-lg-7 about-img" data-aos="zoom-out" data-aos-delay="200">
        <img src={require("../../assets/img/Camionetas/camioneta-1.jpg")} alt=""/>
      </div>

      <div class="col-lg-7" data-aos="fade-up" data-aos-delay="100">
        <h2 class="inner-title">{t("history.title")}</h2>
        <div class="our-story">
         
          <p>
            {t("history.description")}
          </p>
         

        </div>
      </div>
    </div>
  </div>
</section>

        
       
      
         
       
      
      
      
        </main>
      
       
      
        <a
        href="#"
        id="scroll-top"
        class="scroll-top d-flex align-items-center justify-content-center"
      >
        <IoMdArrowUp class="icon-arrow-up" />
      </a>
      
      </div>
    )
}